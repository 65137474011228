<mat-sidenav-container class="mainWrapper" autosize autoFocus [ngClass]="{
    'sidebarNav-mini': options.sidenavCollapsed && options.navPos !== 'top' &&  !resView,
    'sidebarNav-horizontal': options.horizontal,
    cardBorder: options.cardBorder,
    orange_theme: options.activeTheme == 'orange_theme',
    blue_theme: options.activeTheme == 'blue_theme',
    aqua_theme: options.activeTheme == 'aqua_theme',
    purple_theme: options.activeTheme == 'purple_theme',
    green_theme: options.activeTheme == 'green_theme',
    pink_theme: options.activeTheme == 'pink_theme'
  }" [dir]="options.dir!">
  <!-- ============================================================== -->
  <!-- Vertical Sidebar -->
  <!-- ============================================================== -->

  @if(!options.horizontal) {
  <mat-sidenav #leftsidenav [mode]="isOver ? 'over' : 'side'"
    [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver && !resView"
    (openedChange)="onSidenavOpenedChange($event)" (closedStart)="onSidenavClosedStart()" class="sidebarNav">
    <div class="flex-layout">
      <app-sidebar (toggleMobileNav)="sidenav.toggle()" [showToggle]="isOver"></app-sidebar>
      <ng-scrollbar class="position-relative" style="height: 100%">
        <mat-nav-list *ngIf="options.menuItemsDev" class="sidebar-list">
          @for(item of navItems; track item) {
          <app-nav-item [item]="item" (notify)="sidenav.toggle()">
          </app-nav-item>
          }

        </mat-nav-list>
        <mat-nav-list *ngIf="!options.menuItemsDev" class="sidebar-list">
          @for(item of navItemsCustom; track item) {
          <app-nav-item [item]="item" (notify)="sidenav.toggle()">
          </app-nav-item>
          }

        </mat-nav-list>
      </ng-scrollbar>
    </div>
  </mat-sidenav>
  }


  <!-- ============================================================== -->
  <!-- horizontal Sidebar -->
  <!-- ============================================================== -->
  @if(resView) {
  <mat-sidenav #leftsidenav [mode]="'over'" [opened]="options.sidenavOpened && !isTablet"
    (openedChange)="onSidenavOpenedChange($event)" (closedStart)="onSidenavClosedStart()" class="sidebarNav">
    <div class="flex-layout">
      <app-sidebar (toggleMobileNav)="sidenav.toggle()" [showToggle]="isOver"></app-sidebar>
      <ng-scrollbar class="position-relative" style="height: 100%">
        <mat-nav-list *ngIf="options.menuItemsDev" class="sidebar-list">
          @for(item of navItems; track item) {
          <app-nav-item [item]="item" (notify)="sidenav.toggle()">
          </app-nav-item>
          }

        </mat-nav-list>
        <mat-nav-list *ngIf="!options.menuItemsDev" class="sidebar-list">
          @for(item of navItemsCustom; track item) {
          <app-nav-item [item]="item" (notify)="sidenav.toggle()">
          </app-nav-item>
          }

        </mat-nav-list>
      </ng-scrollbar>
    </div>
  </mat-sidenav>
  }

  <!-- ============================================================== -->
  <!-- Main Content -->
  <!-- ============================================================== -->
  <mat-sidenav-content class="contentWrapper" #content>
    <!-- ============================================================== -->
    <!-- VerticalHeader -->
    <!-- ============================================================== -->
    @if(!options.horizontal) {
    <app-header [showToggle]="!isOver" (toggleCollapsed)="toggleCollapsed()"
      (toggleMobileNav)="sidenav.toggle()"></app-header>
    } @else {
    <app-horizontal-header (toggleMobileNav)="sidenav.toggle()"></app-horizontal-header>
    }

    @if(options.horizontal) {
    <app-horizontal-sidebar></app-horizontal-sidebar>
    }


    <main class="pageWrapper" [ngClass]="{
        maxWidth: options.boxed
      }">
      <!-- ============================================================== -->
      <!-- Outlet -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <div *ngIf="!production" class="customizerBtn">
        <button mat-fab color="primary" (click)="customizerRight.toggle()">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </main>
  </mat-sidenav-content>

  <mat-sidenav #customizerRight mode="over" position="end">
    <app-customizer (optionsChange)="receiveOptions($event)"></app-customizer>
  </mat-sidenav>
</mat-sidenav-container>