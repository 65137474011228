import { Component, HostListener  } from '@angular/core';
import { ScriptLoaderService } from './services/script-loader.service';
import { environment } from 'src/environments/environment';
import { MyProfileService } from './services/my-profile.service';
import { AccountInfoModel } from './models/account-info-model';
import { Platform } from '@ionic/angular';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FcmService } from './services/fcm.service';
import { PushNotifications } from '@capacitor/push-notifications';
import { App } from '@capacitor/app';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AuthService } from './services/auth.service';
import { DateAdapter } from '@angular/material/core';
import { take } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Icon Accounting Portal';

  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  constructor(
    private scriptLoader: ScriptLoaderService,
    private myProfileService: MyProfileService,
    private platform: Platform,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar,
    private fcmService: FcmService,
    private overlayContainer: OverlayContainer,
    private authService: AuthService,
    private dateAdapter: DateAdapter<any>
  ) {
    this.dateAdapter.setLocale('en-GB');  
  }

  ngOnInit(): void {
    this.loginCheck();
    // this.loadLiveChat();
    console.log('THIS PLATFORM IS', this.platform.platforms())
    //subscribe to hardware back button only on android
    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        console.log('PLATFORM IS ANDROID')
        this.backButtonEvent();
      }
    });
    window.addEventListener('wheel', this.disableScroll, { passive: false });
  }

  loginCheck() {
    this.authService.user.pipe(take(1)).subscribe(res => {
      console.log(res)
      if (res) {
        // USER LOGGED IN, DO LOGGED IN STUFF ONLY
        this.initializeNotifications();
        this.getAccountInfoData();
      }
    })
  }

  getAccountInfoData() {
    this.myProfileService.getAccountInfo().subscribe(
      (data: AccountInfoModel) => {
        if(data.account.company_type == 'PLC') {
          // LOAD LIVE CHAT ONLY FOR PLC USERS
          this.loadLiveChat();
        }
      },
      err => {
        console.log(err)
      }
    );
  }

  loadLiveChat() {
    if (environment.production) {
      const comm100Div = document.createElement('div');
      comm100Div.id = 'comm100-button-910030f5-ba1e-48ba-891f-af361a03a98e';
      document.body.appendChild(comm100Div);

      const comm100Script = `
        var Comm100API=Comm100API||{};(function(t){function e(e){var a=document.createElement("script"),c=document.getElementsByTagName("script")[0];a.type="text/javascript",a.async=!0,a.src=e+t.site_id,c.parentNode.insertBefore(a,c)}t.chat_buttons=t.chat_buttons||[],t.chat_buttons.push({code_plan:"910030f5-ba1e-48ba-891f-af361a03a98e",div_id:"comm100-button-910030f5-ba1e-48ba-891f-af361a03a98e"}),t.site_id=50100664,t.main_code_plan="910030f5-ba1e-48ba-891f-af361a03a98e",e("https://vue.comm100.com/livechat.ashx?siteId="),setTimeout(function(){t.loaded||e("https://standby.comm100vue.com/livechat.ashx?siteId=")},5e3)})(Comm100API||{})
      `;
      this.scriptLoader.loadInlineScript(comm100Script);
    }
  }

  backButtonEvent(): void {

    App.addListener('backButton', () => {
      // Check if there are any open dialogs
      if (this.dialog.openDialogs.length > 0) {
        this.dialog.closeAll();
        return;
      }

      // Check if there are any open bottom sheets
      if (this.bottomSheet._openedBottomSheetRef) {
        this.bottomSheet.dismiss();
        return;
      }

      // If no dialogs or menus are open, proceed with the normal back button handling
      if (this.router.url === '/dashboard') {
        const currentTime = new Date().getTime();

        if (currentTime - this.lastTimeBackPress < this.timePeriodToExit) {
          App.exitApp();
        } else {
          this._snackBar.open("Press 'Back' again to exit", '', {
            horizontalPosition: 'center', 
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'customSnackBar'
          });
          this.lastTimeBackPress = currentTime;
        }
      } else {
        // Navigate back if not on the root page, except login
        if (this.router.url !== '/login') {
          window.history.back();
        }
      }
    });
  }

  initializeNotifications() {
    this.platform.ready().then(() => {

      if (this.platform.is('capacitor')) {
        //only for mobile check push permission and then get notification settings
        PushNotifications.checkPermissions().then((permission) => {
          if (permission.receive == 'granted') {
            PushNotifications.register();
            setTimeout(() => {
              this.fcmService.getNotificationSettings();
            }, 0);
          }
          else {
            console.log('no permission')
          }
        });
      }
      else {
        //only for web to at least get the settings data on app refresh
        this.fcmService.getNotificationSettings();
      }
    });
  }

  
  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.disableScroll(event);
  }
  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
      this.disableScroll(event);
  }
  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
      this.disableScroll(event);
  }
  disableScroll(event: any) {
      if (event.srcElement.type === "number")
          event.preventDefault();
  }
}
