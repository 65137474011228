<button mat-icon-button [matMenuTriggerFor]="notificationmenu" aria-label="Notifications">
  <i-feather class="d-flex icon-18" name="bell" [matBadge]="notifications.length" [matBadgeHidden]="notifications.length == 0" matBadgeSize="small" matBadgeColor="primary"></i-feather>
</button>
<mat-menu #notificationmenu="matMenu" class="topbar-dd cardWithShadow">
  <div class="d-flex align-items-center p-x-32 p-y-16">
    <h6 class="f-s-16 f-w-600 m-0">Notifications</h6>
    <span class="m-l-auto">
      <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12">{{notifications.length}} new</span>
    </span>
  </div>
  @for(notification of notifications; track notification) {
  <button mat-menu-item class="p-x-32 p-y-16" (click)="handleNotificationClick(notification)">
    <div class="d-flex align-items-center">
      <!-- <img [src]="notification.image" class="rounded-circle" width="48" /> -->
      <i-feather class="d-flex icon-18" name="bell" matBadgeColor="primary"></i-feather>
      <div class="m-l-16">
        <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
          {{ notification.title }}
        </h5>
        <span>{{ notification.subtitle }}</span>
      </div>
    </div>
  </button>
  }

  <!-- <div class="p-y-12 p-x-32">
    <button mat-stroked-button color="primary" class="w-100">
      See all notifications
    </button>
  </div> -->
</mat-menu>