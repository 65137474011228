import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class ClientStatusGuard implements CanActivate {

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const { value: clientStatus } = await Preferences.get({ key: 'client-status' });
      const blockedStatuses: number[] = route.data['blockedStatuses'] || [];

      console.log('Client Status:', clientStatus, 'Blocked Statuses:', blockedStatuses);

      if (clientStatus && blockedStatuses.includes(parseInt(clientStatus, 10))) {
        this.snackBar.open('Limited Access. Please contact your manager.', 'Close', { horizontalPosition: 'center', verticalPosition: 'top',  duration: 3000, panelClass: 'customSnackBar' });
        await this.router.navigate(['/dashboard']); // Redirect to a safe route
        return false;
      }

      return true;
    } catch (error) {
      console.error('Error retrieving client status:', error);
      // Optionally, handle the error, like redirecting to an error page
      return false;
    }
  }
}
