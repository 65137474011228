import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { from, Observable,lastValueFrom, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService, TOKEN_KEY } from '../services/auth.service';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Platform } from '@ionic/angular';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  type: 'iOS' | 'Android' | 'Web' = this.platform.is('capacitor') ? this.platform.is('android') ? 'Android' : 'iOS' : 'Web';

  constructor(private authService: AuthService, private router: Router, private platform: Platform,) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Handle 401 error here (e.g., redirect to login page or perform any desired action)
          // You can also throw an error to propagate it to the caller
          // throw new Error('Unauthorized');
          console.log('ERROR AUTH')
          this.authService.logout();
          this.router.navigateByUrl('/authentication/login', { replaceUrl: true });
        }
        return throwError(() => error);
      })
    );
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler):Promise<HttpEvent<any>> {
    let token;

    try {
      token = await SecureStoragePlugin.get({ key: TOKEN_KEY });
    } catch (error) {
      console.log('no token in storage');
    }
    const isApiUrl = request.url.startsWith(environment.restApiUrl);

    if (token && isApiUrl) {
        request = request.clone({
            setHeaders: { 
                Authorization: `Bearer ${token.value}`,
                'x-platform': this.type + ' (' + environment.version + ')'
            }
        });
    } 
    else if(isApiUrl) {
      request = request.clone({
        setHeaders: {
            'x-platform': this.type + ' (' + environment.version + ')'
        }
      });
    }

    return lastValueFrom(next.handle(request));
  }
}