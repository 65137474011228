import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  loadScript(src: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  loadInlineScript(inlineScript: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = inlineScript;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
