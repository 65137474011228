import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { LoginGuard } from './guards/login.guard';
import { AuthGuard } from './guards/auth.guard';
import { AddInvoiceComponent } from './pagesCustom/invoices/add-invoice/add-invoice.component';
import { ClientStatusGuard } from './guards/clientStatus.guard';
import { DashboardComponent } from './pagesCustom/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'starter',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
      },
      {
        path: 'dashboards',
        loadChildren: () =>
          import('./pages/dashboards/dashboards.module').then(
            (m) => m.DashboardsModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'ui-components',
        loadChildren: () =>
          import('./pages/ui-components/ui-components.module').then(
            (m) => m.UicomponentsModule
          ),
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./pages/forms/forms.module').then((m) => m.FormModule),
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./pages/charts/charts.module').then((m) => m.ChartsModule),
      },
      {
        path: 'apps',
        loadChildren: () =>
          import('./pages/apps/apps.module').then((m) => m.AppsModule),
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./pages/widgets/widgets.module').then((m) => m.WidgetsModule),
      },
      {
        path: 'tables',
        loadChildren: () =>
          import('./pages/tables/tables.module').then((m) => m.TablesModule),
      },
      {
        path: 'theme-pages',
        loadChildren: () =>
          import('./pages/theme-pages/theme-pages.module').then(
            (m) => m.ThemePagesModule
          ),
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('./pagesCustom/invoices/invoice-routing.module').then(
            (m) => m.InvoicesModule
          ),
        title: 'Invoices',
        canActivate: [ClientStatusGuard],
        data: { blockedStatuses: [5, 6, 7, 8] }
      },
      {
        path: 'expenses',
        loadComponent: () =>
          import('./pagesCustom/expenses/expenses.component').then(
            (m) => m.ExpensesComponent
          ),
          title: 'Expenses',
          canActivate: [ClientStatusGuard],
          data: { blockedStatuses: [5, 6, 7, 8] }
      },
      {
        path: 'taxreturn',
        loadComponent: () =>
          import('./pagesCustom/tax-return/tax-return.component').then(
            (m) => m.TaxReturnComponent
          ),
          title: 'Tax return'
      },
      {
        path: 'documents',
        loadComponent: () =>
          import('./pagesCustom/documents/documents.component').then(
            (m) => m.DocumentsComponent
          ),
          title: 'Documents',
          canActivate: [ClientStatusGuard],
          data: { blockedStatuses: [7] }
      },
      {
        path: 'profile',
        loadComponent: () =>
          import('./pagesCustom/my-profile/my-profile.component').then(
            (m) => m.MyProfileComponent
          ),
          title: 'My profile'
      },
      {
        path: 'payslips',
        loadComponent: () =>
          import('./pagesCustom/payslips/payslips.component').then(
            (m) => m.PayslipsComponent
          ),
          title: 'Payslips',
          canActivate: [ClientStatusGuard],
          data: { blockedStatuses: [5, 7] }
      },
      {
        path: 'knowledge-base-v2',
        loadComponent: () =>
          import('./pagesCustom/knowledge-base-v2/knowledge-base-v2.component').then(
            (m) => m.KnowledgeBaseV2Component
          ),
          title: 'Knowledge Base'
      },
      {
        path: 'knowledge-base-v2/article/:id',
        loadComponent: () =>
          import('./pagesCustom/knowledge-base-v2/article-template/article-template.component').then(
            (m) => m.ArticleTemplateComponent
          ),
          title: 'Knowledge Base'
      },
      {
        path: 'knowledge-base-v2/category/:id',
        loadComponent: () =>
          import('./pagesCustom/knowledge-base-v2/category-template/category-template.component').then(
            (m) => m.CategoryTemplateComponent
          ),
          title: 'Knowledge Base'
      },
      {
        path: 'knowledge-base-v2/search',
        loadComponent: () =>
          import('./pagesCustom/knowledge-base-v2/search-template/search-template.component').then(
            (m) => m.SearchTemplateComponent
          ),
          title: 'Knowledge Base'
      },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('./pagesCustom/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          ),
        title: 'Dashboard'
      },
      {
        path: 'idp/sso/redirect',
        loadComponent: () =>
          import('./pagesCustom/dashboard/i-perks/sso/sso.component').then(
            (m) => m.SsoComponent
          ),
          title: 'iPERKS SSO'
      },
      {
        path: 'dashboard/tax-saver',
        loadComponent: () =>
          import('./pagesCustom/dashboard/tax-saver-card/tax-saver/tax-saver.component').then(
            (m) => m.TaxSaverComponent
          ),
          title: 'Tax Saver'
      },
    ],
    canLoad: [AuthGuard]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
        canLoad: [LoginGuard],
      },
    ],
  },
  {
    path: 'referral-form',
    loadComponent: () =>
      import('./pagesCustom/referral/referral.component').then(
        (m) => m.ReferralComponent
      ),
      title: 'Referral Form'
  },
  {
    path: 'modify-form/:id',
    loadComponent: () =>
      import('./pagesCustom/standalone-forms/modify-form/modify-form.component').then(
        (m) => m.ModifyFormComponent
      ),
      title: 'Modify Form'
  },
  {
    path: 'unsubscribe/:id',
    loadComponent: () =>
      import('./pagesCustom/standalone-forms/unsubscribe-form/unsubscribe-form.component').then(
        (m) => m.UnsubscribeFormComponent
      ),
      title: 'Unsubscribe Form'
  },
  {
    path: 'starter-form/:id',
    loadComponent: () =>
      import('./pagesCustom/standalone-forms/starter-form/starter-form.component').then(
        (m) => m.StarterFormComponent
      ),
      title: 'Starter Form'
  },
  {
    path: 'company-form/:id',
    loadComponent: () =>
      import('./pagesCustom/standalone-forms/company-form/company-form.component').then(
        (m) => m.CompanyFormComponent
      ),
      title: 'Company Order Form'
  },
  {
    path: 'contract-ending-form/:id',
    loadComponent: () =>
      import('./pagesCustom/standalone-forms/contract-ending-form/contract-ending-form.component').then(
        (m) => m.ContractEndingFormComponent
      ),
      title: 'Contract Ending Form'
  },
  {
    path: 'from-admin/:token',
    loadComponent: () =>
      import('./pagesCustom/from-admin/from-admin.component').then(
        (m) => m.FromAdminComponent
      ),
      title: 'Admin Login As'
  },
  {
    path: 'version',
    loadComponent: () =>
      import('./pagesCustom/version/version.component').then(
        (m) => m.VersionComponent
      ),
      title: 'Version'
  },
  {
    path: '**',
    redirectTo: 'authentication/error',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
