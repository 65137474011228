import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router, 
    private auth: AuthService,
  ) {}
  
  canLoad(): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => {
        if (!user) {
          // TODO provide error from API for toast
          //this.toast.presentToast('Unauthorized!');
          console.log('Unauthorized');
          this.router.navigateByUrl('/authentication/login');

          return false;
        }

        return true;
      })
    )
  }
  
}
