import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatherModule } from 'angular-feather';
import { Subscription, take } from 'rxjs';
import { MaterialModule } from 'src/app/material.module';
import { ApiNotificationsService } from 'src/app/services/api-notifications.service';

export interface notifications {
  ntype: number;
  title: string;
  subtitle: string;
  url: string;
  image: string;
}

@Component({
  selector: 'app-header-notifications',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    FeatherModule
  ],
  templateUrl: './header-notifications.component.html',
  styleUrls: ['./header-notifications.component.scss'],
})
export class HeaderNotificationsComponent  implements OnInit {

  notifications: notifications[] = [];

  private pollingSubscription: Subscription;

  constructor(
    private apiNotificationsService: ApiNotificationsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loadNotifications();
    this.startPollingNotifications();
  }

  loadNotifications(): void {
    this.apiNotificationsService.getNotifications().pipe(take(1)).subscribe({
      next: (data: notifications[]) => {
        this.notifications = data;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  startPollingNotifications(): void {
    const intervalTime = 900000; // Poll every 15 minutes
    this.pollingSubscription = this.apiNotificationsService.startPolling(intervalTime).subscribe({
      next: (data: notifications[]) => {
        this.notifications = data;
      },
      error: error => {
        console.error(error);
      }
    });
  }

  handleNotificationClick(notification: notifications): void {
    this.apiNotificationsService.markNotificationsAsRead(notification.ntype).pipe(take(1)).subscribe({
      next: res => {
        this.loadNotifications();
      },
      error: err => {
        console.log(err)
      }
    });

    if (notification.url.startsWith('http') || notification.url.startsWith('www')) {
      // External URL
      window.location.href = notification.url;
    } else {
      // Internal URL
      this.router.navigate([notification.url]);
    }
  }

  ngOnDestroy(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
    this.apiNotificationsService.stopPollingNotifications();
  }

}
