import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard {
  constructor(
    private router: Router, 
    private auth: AuthService,
  ) {}
  
  canLoad(): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => {
        if (!user) {
            return true;
        }
        console.log('Authorized');
        this.router.navigateByUrl('/');
        return false;      
      })
    )
  }
  
}
