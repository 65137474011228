<mat-toolbar class="topbar">
  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
   @if (!isMobileView) {
    <button
      mat-icon-button
      (click)="toggleCollapsed.emit()"
      class="d-flex justify-content-center"
    >
      <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
    </button>
  }

  <!-- Mobile Menu -->
  @if (!showToggle) {
    <!-- <button
      mat-icon-button
      (click)="toggleMobileNav.emit()"
      class="d-flex justify-content-center"
    >
      <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
    </button> -->
  }

  <!-- --------------------------------------------------------------- -->

  <!-- --------------------------------------------------------------- -->
  <!--  Search -->
  <!-- --------------------------------------------------------------- -->
  <!-- <button
    mat-icon-button
    (click)="openDialog()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="search" class="icon-20 d-flex"></i-tabler>
  </button> -->

  <span class="flex-1-auto"></span>

  <!-- --------------------------------------------------------------- -->
  <!-- langugage Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!-- <button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
    <img [src]="selectedLanguage.icon" class="rounded-circle object-cover icon-20" />
  </button>
  <mat-menu #flags="matMenu" class="cardWithShadow">
    @for(lang of languages; track lang) {
    <button mat-menu-item (click)="changeLanguage(lang)">
      <div class="d-flex align-items-center">
        <img [src]="lang.icon" class="rounded-circle object-cover m-r-8 icon-20" />
        <span class="mat-subtitle-1 f-s-14">{{ lang.language }}</span>
      </div>
    </button>
    }

  </mat-menu> -->

  <!-- --------------------------------------------------------------- -->
  <!-- Messages Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!-- <button mat-icon-button [matMenuTriggerFor]="msgmenu" aria-label="Notifications">
    <i-feather class="d-flex icon-18" name="message-square" matBadge="2" matBadgeSize="small"
      matBadgeColor="accent"></i-feather>
  </button>
  <mat-menu #msgmenu="matMenu" class="topbar-dd cardWithShadow">
    <div class="d-flex align-items-center p-x-32 p-y-16">
      <h6 class="f-s-16 f-w-600 m-0">Messages</h6>
      <span class="m-l-auto">
        <span class="bg-accent text-white p-x-8 p-y-4 f-w-500 rounded f-s-12">2 new</span>
      </span>
    </div>
    @for(notification of notifications; track notification) {
    <button mat-menu-item class="p-x-32 p-y-16">
      <div class="d-flex align-items-center">
        <img [src]="notification.image" class="rounded-circle" width="48" />
        <div class="m-l-16">
          <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
            {{ notification.title }}
          </h5>
          <span>{{ notification.subtitle }}</span>
        </div>
      </div>
    </button>
    }

    <div class="p-y-12 p-x-32">
      <button mat-stroked-button color="accent" class="w-100">
        See all notifications
      </button>
    </div>
  </mat-menu> -->

  <!-- --------------------------------------------------------------- -->
  <!-- iPERKS Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-icon-button [matMenuTriggerFor]="iPerksMenu" aria-label="iPERKS">
    <i-tabler name="heart-handshake" class="icon-24"></i-tabler>
  </button>

  <mat-menu #iPerksMenu="matMenu" class="topbar-dd cardWithShadow">
    <app-iPerks></app-iPerks>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- Manager Info Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-icon-button [matMenuTriggerFor]="managerInfoMenu" aria-label="Manager Info">
    <i-tabler name="tie" class="icon-24"></i-tabler>
  </button>

  <mat-menu #managerInfoMenu="matMenu" class="topbar-dd cardWithShadow">
    <app-account-manager-info [clientData]="clientData"></app-account-manager-info>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- Send feedback Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-icon-button [matMenuTriggerFor]="sendFeedbackMenu" aria-label="Send feedback">
    <i-tabler name="message-2-heart" class="icon-24"></i-tabler>
  </button>

  <mat-menu #sendFeedbackMenu="matMenu" class="topbar-dd cardWithShadow">
    <app-send-feedback-card></app-send-feedback-card>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- Refer a friend Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-icon-button [matMenuTriggerFor]="referFriendMenu" aria-label="Refer a friend">
    <i-tabler name="users-group" class="icon-24"></i-tabler>
  </button>

  <mat-menu #referFriendMenu="matMenu" class="topbar-dd cardWithShadow">
    <app-refer-friend-card [profileData]="profileData"></app-refer-friend-card>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- Notification Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <app-header-notifications></app-header-notifications>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="profilemenu"
    aria-label="Profile"
  >
    <img
      [src]="avatarUrl"
      class="rounded-circle object-cover icon-35 profile-dd"
      
    />
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <ng-scrollbar class="position-relative" style="height: 300px">
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0">User Profile</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <img [src]="avatarUrl" class="rounded-circle object-cover profile-dd icon-45" />
          <div class="m-l-16">
            <h6 class="f-s-14 f-w-600 m-0">{{ profileData?.full_name }}</h6>
            <span class="d-flex align-items-center">
              <i-tabler name="mail" class="icon-15 m-r-4"></i-tabler>
              {{ profileData?.email }}
            </span>
          </div>
        </div>
      </div>
      <div class="p-x-32">
        @for (profile of profiledd; track profile) {
          <a
            class="p-y-16 text-decoration-none d-block text-hover-primary"
            [routerLink]="[profile.link]"
          >
            <div class="d-flex align-items-center">
              <button
                mat-mini-fab
                class="text-primary bg-light-primary shadow-none rounded"
              >
                <img [src]="profile.img" width="30" />
              </button>

              <div class="m-l-16">
                <h5
                  class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text"
                >
                  {{ profile.title }}
                </h5>
                <span class="mat-body-1">{{ profile.subtitle }}</span>
              </div>
            </div>
          </a>
        }
      </div>

      <div class="p-y-12 p-x-32">
        <button
          (click)="logout()"
          mat-stroked-button
          color="primary"
          class="w-100"
        >
          Logout
        </button>
      </div>
    </ng-scrollbar>
  </mat-menu>
</mat-toolbar>
