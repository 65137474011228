import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { MatDialog } from '@angular/material/dialog';
import { navItems } from '../sidebar/sidebar-data';
import { TranslateService } from '@ngx-translate/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FeatherModule } from 'angular-feather';
import { AuthService } from 'src/app/services/auth.service';
import {
  HeaderNotificationsComponent,
  notifications,
} from 'src/app/componentsCustom/header-notifications/header-notifications.component';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { IPerksComponent } from 'src/app/pagesCustom/dashboard/i-perks/i-perks.component';
import { SendFeedbackCardComponent } from 'src/app/pagesCustom/dashboard/send-feedback-card/send-feedback-card.component';
import { ReferFriendCardComponent } from 'src/app/pagesCustom/dashboard/refer-friend-card/refer-friend-card.component';
import { AccountManagerInfoComponent } from 'src/app/pagesCustom/dashboard/account-manager-info/account-manager-info.component';
import { ClientData } from 'src/app/pagesCustom/invoices/invoices-models';
import { DashboardPageService } from 'src/app/services/dashboard-page.service';
import { MobileViewService } from 'src/app/services/mobile-view.service';
import { Subject, takeUntil } from 'rxjs';
import { addIcons } from "ionicons";
import { environment } from 'src/environments/environment';

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface apps {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface quicklinks {
  id: number;
  title: string;
  link: string;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    NgScrollbarModule,
    TablerIconsModule,
    MaterialModule,
    FeatherModule,
    HeaderNotificationsComponent,
    IPerksComponent,
    SendFeedbackCardComponent,
    ReferFriendCardComponent,
    AccountManagerInfoComponent
  ],
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  @Input() showToggle = true; //theme way of showing/hiding desktop/mobile menu buttons
  isMobileView: boolean = false; //custom way of showing/hiding menu button
  private unsubscribe$ = new Subject<void>();
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();
  profileData: any;
  avatarUrl: string = '/assets/images/profile/user-1.jpg';

  showFiller = false;

  apiUrl = environment.portalUrl;

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: '/assets/images/flag/icon-flag-en.svg',
  };

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: '/assets/images/flag/icon-flag-en.svg',
    },
    {
      language: 'Español',
      code: 'es',
      icon: '/assets/images/flag/icon-flag-es.svg',
    },
    {
      language: 'Français',
      code: 'fr',
      icon: '/assets/images/flag/icon-flag-fr.svg',
    },
    {
      language: 'German',
      code: 'de',
      icon: '/assets/images/flag/icon-flag-de.svg',
    },
  ];

  clientData: ClientData;

  constructor(
    private vsidenav: CoreService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private authService: AuthService,
    private myProfileService: MyProfileService,
    private dashboardService: DashboardPageService,
    private mobileViewService: MobileViewService
  ) {
    translate.setDefaultLang('en');
    this.getProfileData();
    this.getClientData();
    this.checkMobileView();
    this.checkForProfileUpdate();
  }

  ngOnDestroy() {
    // Trigger the unsubscribe
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkForProfileUpdate() {
    this.myProfileService.profileUpdated$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.getProfileData();
    });
  }

  checkMobileView() {
    this.mobileViewService.getIsMobileView().pipe(takeUntil(this.unsubscribe$)).subscribe(isMobile => {
      this.isMobileView = isMobile;
    });
  }

  getClientData() {
    this.dashboardService.clientData.subscribe(res => {
      if (res) {
        this.clientData = res;      
      }
    });
  }

  getProfileData() {
    this.myProfileService.getPersonalInfo().subscribe((data) => {
      this.profileData = data.profile;
    });
    this.myProfileService.getAvatar().subscribe((data: any) => {
      this.avatarUrl = this.apiUrl + data.url;
      if (this.avatarUrl.includes('no-image')) {
        this.resetAvatar();
      }
    });
  }

  resetAvatar() {
    this.avatarUrl = '/assets/images/profile/user-1.jpg';
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppSearchDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  changeLanguage(lang: any): void {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  notifications: notifications[] = [
    {
      ntype: 1,
      image: '/assets/images/profile/user-1.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulate him',
      url: '',
    },
    {
      ntype: 2,
      image: '/assets/images/profile/user-2.jpg',
      title: 'New message received',
      subtitle: 'Salma sent you new message',
      url: '',
    },
    {
      ntype: 3,
      image: '/assets/images/profile/user-3.jpg',
      title: 'New Payment received',
      subtitle: 'Check your earnings',
      url: '',
    },
    {
      ntype: 4,
      image: '/assets/images/profile/user-4.jpg',
      title: 'Jolly completed tasks',
      subtitle: 'Assign her new tasks',
      url: '',
    },
    {
      ntype: 5,
      image: '/assets/images/profile/user-5.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulate him',
      url: '',
    },
  ];

  profiledd: profiledd[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'My Profile',
      subtitle: 'Account Settings',
      link: '/profile',
    },
    // {
    //   id: 2,
    //   img: '/assets/images/svgs/icon-inbox.svg',
    //   title: 'My Inbox',
    //   subtitle: 'Messages & Email',
    //   link: '/apps/email/inbox',
    // },
    // {
    //   id: 3,
    //   img: '/assets/images/svgs/icon-tasks.svg',
    //   title: 'My Tasks',
    //   subtitle: 'To-do and Daily Tasks',
    //   link: '/apps/taskboard',
    // },
  ];

  logout() {
    this.authService.logout();
  }
}

@Component({
  selector: 'search-dialog',
  standalone: true,
  imports: [RouterModule, MaterialModule, TablerIconsModule, FormsModule],
  templateUrl: 'search-dialog.component.html',
})
export class AppSearchDialogComponent {
  searchText: string = '';
  navItems = navItems;

  navItemsData = navItems.filter((navitem) => navitem.displayName);

  // filtered = this.navItemsData.find((obj) => {
  //   return obj.displayName == this.searchinput;
  // });
}
